<template>
  <div>
    <SchoolsTable/>
  </div>
</template>
<script>
import SchoolsTable from "@/components/school/SchoolInfoTable";
export default {
  name: 'Schools',
  components: {SchoolsTable}
}
</script>

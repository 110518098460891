<template>
  <v-row>
    <v-dialog
        v-model="dialog"
        content-class="school-edit-dialog"
        hide-overlay
        fullscreen
        persistent
        transition="dialog-bottom-transition"
    >
      <div>
        <v-toolbar flat>
          <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
                icon
                dark
                @click="dialog = false"
            >
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </div>
      <v-card
        :loading="isSaving"
        min-height="auto"
        flat
      >
        <v-form
          v-model="isSubmitValid"
          ref="editForm"
          class="pa-5"
        >
          <v-row>
            <v-col cols="4">
              <text-field
                v-model="item.name"
                name="Name*"
                :rules="nameRules"
                placeholder="School Name"
                solo
                required
                :hideDetails="isSubmitValid"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="pr-15">
              <text-field
                v-model="item.email_suffix"
                name="Email Suffix"
                placeholder="@example.com"
                solo
                :hideDetails="isSubmitValid"
              />
            </v-col>
            <v-col cols="4">
              <text-field
                v-model="item.postal_code"
                name="Postal Code"
                placeholder="Postal Code"
                solo
                :hideDetails="isSubmitValid"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <text-field
                v-model="item.nces_id"
                name="NCES ID"
                placeholder="NCES ID"
                solo
              />
            </v-col>
            <v-col cols="1"/>
            <v-col class="pt-12" cols="1">
               <v-checkbox
                v-model="item.is_district"
                label="District?"
              ></v-checkbox>
            </v-col>
            <v-col class="pt-12" cols="1">
               <v-checkbox
                v-model="item.active"
                label="Active?"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <text-field
                v-model="item.formatted_address_lines"
                name="Full Address"
                placeholder="School Address"
                solo
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pr-15" cols="2">
              <text-field
                v-model="item.city"
                name="City"
                placeholder="City"
                solo
              />
            </v-col>
            <v-col class="pr-15" cols="2">
              <text-field
                v-model="item.administrative_area"
                name="State / Administrative Area "
                placeholder="State"
                solo
              />
            </v-col>
            <v-col cols="1">
              <dropdown
                v-model="item.country_code"
                :items="countries"
                name="Country*"
                placeholder="Select Country"
                :rules="countryCodeRules"
                :hideDetails="isSubmitValid"
                solo
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="pr-15">
              <text-field
                v-model="item.phone_number"
                name="Phone Number"
                placeholder="Phone Number"
                solo
                :hideDetails="isSubmitValid"
              />
            </v-col>
            <v-col cols="4">
              <dropdown
                v-model="item.students_count"
                :items="studentsCountList"
                name="Number of students in school*"
                placeholder="Select students count"
                :hideDetails="isSubmitValid"
                solo
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pr-15" cols="2">
              <text-field
                v-model="item.latitude"
                type="number"
                name="Latitude"
                placeholder="1.0"
                solo
              />
            </v-col>
            <v-col class="pr-15" cols="2">
              <text-field
                v-model="item.longitude"
                type="number"
                name="Longitude"
                placeholder="1.0"
                solo
              />
            </v-col>
          </v-row>
          <v-row class="mt-15">
            <v-col cols="4"/>
            <v-col
              class="text-end"
              cols="2"
            >
              <v-btn
                class="btn-save-school"
                max-width="90"
                min-height="40"
                color="primary"
                :disabled="!isSubmitValid"
                @click="onSave"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

import _ from "lodash"
import TextField from '@/components/base/TextField'
import Dropdown from '@/components/base/Dropdown'
import {EventBus, NOTIFY_EVENT, SCHOOL_CREATED_EVENT, SCHOOL_UPDATED_EVENT, CUSTOM_SCHOOL_CONVERTED_EVENT} from "@/eventbus";
import { STUDENTS_COUNT_LIST, COUNTRY_DATA } from '@/constants'
import {createSchools, updateSchool, updateCustomSchool, getMapLocation} from '@/api/api';

export default {
  name: 'SchoolEditorDialog',
  props: {
    onClose: {
      type: Function,
      default() {
        return {}
      }
    }
  },
  watch: {
    dialog: function (newVal) {
      if (!newVal) this.onClose()
    }
  },
  components: {
    TextField, Dropdown
  },
  computed: {
    dialogTitle() {
      if (!this.item.id && !this.item.custom_school_id) {
        return 'Create School'
      } else {
        return 'School Info Editor'
      }
    },
  },
  created() {
    this.countries = _.map(COUNTRY_DATA, (country) => country.alpha3).sort((a, b) => a.localeCompare(b))
  },
  methods: {
    show(item, address) {
      this.item = null
      this.dialog = true
      this.item = _.cloneDeep(item)
      this.address = address || this.item.formatted_address_lines;
      this.loadLocationInfo()
    },
    getFullAddressForMap() {
      const country = _.find(COUNTRY_DATA, (country) => country.alpha3 === this.item.country_code || country.alpha2 === this.item.country_code)?.name;
      return this.address && country ? [this.address, this.item.city, country].join(',') : '';
    },
    async loadLocationInfo() {
      if (this.item.latitude || this.item.longitude) return
      let latitude = null
      let longitude = null
      const results = await getMapLocation(
        this.item.country_code || '',
        this.item.city || '',
        this.getFullAddressForMap()
      )
      if (results.length > 0) {
        latitude = parseFloat(results[0].lat)
        longitude = parseFloat(results[0].lon)
      }

      this.item = {
        ...this.item,
        latitude,
        longitude
      }
    },
    getSchoolData(schoolInfo) {
      return {
        ...schoolInfo,
        latitude: schoolInfo.latitude && parseFloat(schoolInfo.latitude),
        longitude: schoolInfo.longitude && parseFloat(schoolInfo.longitude)
      }
    },
    async createSchoolData() {
      try {
        let isSucceed = false
        let result = null;
        if (this.item.custom_school_id) {
          const { custom_school_id, ...schoolData } = this.item
          result = await updateCustomSchool(custom_school_id, this.getSchoolData(schoolData))
          isSucceed = result.status === 201
        } else {
          result = await createSchools([this.getSchoolData(this.item)])
          isSucceed = result && result.length > 0
        }
        if (isSucceed) {
          if (this.item.custom_school_id) {
            EventBus.$emit(CUSTOM_SCHOOL_CONVERTED_EVENT)
            this.notify(`School ${this.item.name} was updated`, 'success')
          } else {
            EventBus.$emit(SCHOOL_CREATED_EVENT)
            this.notify(`School ${this.item.name} was created`, 'success')
          }
        }
        return isSucceed
      } catch {
        return false
      }
    },
    async updateSchoolData() {
      try {
        const result = await updateSchool(this.getSchoolData(this.item))
        if (result && result.id) {
          EventBus.$emit(SCHOOL_UPDATED_EVENT, this.item);
          this.notify(`School ${this.item.name} was updated`, 'success')
          return true
        } else {
          return false
        }
      } catch {
        return false
      }
    },
    notify(message, type) {
      EventBus.$emit(NOTIFY_EVENT, {message, type})
    },
    async onSave() {
      if (!this.isSubmitValid) return

      this.isSaving = true
      let success = false
      if (!this.item.id) {
        success = await this.createSchoolData()
      } else {
        success = await this.updateSchoolData()
      }

      if (success) {
        this.dialog = false
      } else {
        this.notify('Something went wrong', 'error')
      }
      this.isSaving = false
    }
  },
  data() {
    return {
      dialog: false,
      isSaving: false,
      item: {},
      studentsCountList: STUDENTS_COUNT_LIST,
      countries: [],
      nameRules: [
        v => !!v || 'Name is required'
      ],
      countryCodeRules: [
        v => !!v || 'Country code is required'
      ],
      isSubmitValid: false
    }
  },

}
</script>

<style scoped>
.btn-save-school {
  width: 100%;
}
</style>

<style>
.school-edit-dialog {
  left: 257px !important;
  width: calc(100% - 257px) !important;
  background: #fff;
}
</style>
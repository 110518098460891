<template>
	<div>
		<v-card-title	
			class="px-0 py-1"
			v-if="name"
		>
			{{ name }}
		</v-card-title>
		<v-select
			:value="value"
			:items="items"
			:item-value="itemValue"
			:item-text="itemText"
			:label="label"
			:placeholder="placeholder"
			:rules="rules"
			:solo="solo"
			:dense="dense"
			:hide-details="hideDetails"
			@input="onInputField"
			@change="onChangeField"
		></v-select>
	</div>
</template>

<script>
export default {
	props: {
		name: String,
		value: String,
		items: {
			type: Array,
			default() {
				return []
			}
		},
		label: String,
		placeholder: String,
		itemValue: String,
		itemText: String,
		solo: Boolean,
		dense: Boolean,
		rules: {
			type: Array,
			default() {
				return []
			}
		},
		hideDetails: {
			type: Boolean,
			default: true
		}
	},
	methods: {
		onInputField(val) {
			this.$emit('input', val)
		},
		onChangeField(val) {
			this.$emit('change', val)
		}
	}
}
</script>
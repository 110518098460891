<template>
  <v-card>
    <v-card-text>
      <sub-plans-tree-view></sub-plans-tree-view>
    </v-card-text>
	<search-auto-complete :searchList="getSchoolData"/>
  </v-card>
</template>

<script>
import _ from "lodash";
import SubPlansTreeView from "@/components/subscription/SubsPlansTreeView";
import SearchAutoComplete from "@/components/school/SearchAutoComplete";
import { getSchools } from '@/api/api'

export default {
  components: {SubPlansTreeView, SearchAutoComplete},
  data: function() {
    return {}
  },
  created() {
    this.debouncedGetDataFromApi = _.debounce(function () {
      this.getDataFromApi()
    }, 1000, {leading: true})
  },
  computed: {},
  watch: {},
  name: "",
  methods: {
     async getDataFromApi() {
      const {sortBy, sortDesc} = this.options
      let url = `/user/list?`
      if (this.role) {
        url += `role=${this.role}&`
      }
      url += `limit=50&offset=0&sort=${sortBy}&desc=${sortDesc}&${this.querySeachParams}`;
      if (url !== this.lastFetchQuery) {
        this.isLoading = true
        try {
          const {data, status} = await this.axios(url);
          if (status !== 200) {
            this.isLoading = false;
            this.$root.$emit('notify', {message: `Server returned with status code: ${status}`, type: 'error'})
          }
          this.users = data.items.map(item => ({...item, roles: JSON.parse(item.roles), user_settings: JSON.parse(item.user_settings)}));


        }
        catch (e) {
          console.error(e);
        }
        finally {
          this.isLoading = false
        }
      }
    },
	async getSchoolData(query) {
		const result = await getSchools(query)
		return result.hits || []
	}
  },
}
</script>


<style scoped>

</style>

<template>
<v-card v-if="!isEditorVisible">
  <DeleteDialog ref="deleteDialog" target="School Account" v-on:delete-dialog-confirmation="deleteCurrentItem($event)"></DeleteDialog>
  <v-card-title>Premium School Accounts</v-card-title>
	<v-container class="pt-0">
		<v-row>
			<v-col>
				<v-text-field
					v-model="options.search"
					class="mt-0 pt-0"
					append-icon="mdi-magnify"
					label="Search"
					single-line
					clearable
					hide-details
					@click:append="getDataFromApi"
					@keydown.enter="getDataFromApi"
				></v-text-field>
			</v-col>
			<v-col cols="1">
				<v-btn color="primary" @click="openItem()">New...</v-btn>
			</v-col>
		</v-row>
	</v-container>
<v-progress-linear v-if="progress" indeterminate></v-progress-linear>
<v-data-table
    :headers="headers"
    :items="schools"
    :options.sync="options"
    :server-items-length="totalSchools"
    :loading="loading"
    :search="options.search"
    class="elevation-1"
    @click:row="openItem"
>
  <template v-slot:item.v_customer_id="{ item }">
    <a
      :href="getStripeCustomerLink(item)"
      target="_blank"
      @click.stop="() => {}"
    >
      {{ item.v_customer_id }}
    </a>
  </template>
  <template v-slot:item.actions="{ item }">
    <v-icon
        class="mr-2"
        @click.stop.prevent="openItem(item)"
    >
      mdi-pencil
    </v-icon>
    <v-icon color.hover="red"
		@click.stop.prevent="showDeleteDialog(item)"
    >
      mdi-delete
    </v-icon>
  </template>
</v-data-table>
</v-card>
<PremiumSchoolEditor ref="schoolEditor" @onClose="isEditorVisible = false" v-else/>
</template>
<script>
import _ from 'lodash'
import DeleteDialog from "@/components/DeleteDialog";
import PremiumSchoolEditor from './PremiumSchoolEditor.vue'
import {EventBus, PREMIUM_SCHOOL_CREATED_EVENT, PREMIUM_SCHOOL_UPDATED_EVENT} from "@/eventbus";
import {getPremiumSchoolTotalCount, getPremiumSchools, searchPremiumSchools, deletePremiumSchool} from '@/api/api';
import { getMergedSchoolAccountStatus } from '@/utils'

export default {

  name: 'PremiumSchoolsTable',
  components: {
    DeleteDialog, PremiumSchoolEditor
  },
  data: () => {
    return {
      progress: false,
      dialog: false,
      headers: [
        {text: 'School Name', value: 'name'},
        // {text: 'Num Of Teachers', value: 'teachers'},
        {text: 'Status', value: 'status'},
        {text: 'Sub start date', value: 'sub_start_date'},
        {text: 'Sub end date', value: 'sub_end_date'},
        {text: 'Customer Id', value: 'v_customer_id'},
        {text: 'Actions', value: 'actions', sortable: false, width: '100px'},
      ],
      schools: [],
      search: null,
      options: {
        search: ''
      },
      totalSchools: 0,
      loading: false,
      isEditorVisible: false
    }
  },
  watch: {
    options: {
      handler() {
        this.debouncedGetDataFromApi()
      },
      deep: true,
    }
  },
  async created() {
    this.debouncedGetDataFromApi = _.debounce(function () {
      this.getDataFromApi()
    }, 500, {leading: false})
    await this.getTotalSchoolCount()

    EventBus.$on(PREMIUM_SCHOOL_CREATED_EVENT, async () => {
      await this.getTotalSchoolCount()
      await this.getDataFromApi()
    })

    EventBus.$on(PREMIUM_SCHOOL_UPDATED_EVENT, (item) => {
      const school = _.find(this.schools, {id: item.id})
      if (school) {
        _.assign(school, item);
      }
    })
  },
  methods: {
    getStripeCustomerLink(item) {
      return item.v_customer_id ? `https://dashboard.stripe.com/customers/${item.v_customer_id}` : ''
    },
    showDeleteDialog(item) {
      this.$refs.deleteDialog.show(item.name, item);
    },
    async getTotalSchoolCount() {
      this.totalSchools = await getPremiumSchoolTotalCount()
    },
    async deleteCurrentItem(item) {
      this.progress = true
      try {
        const result = await deletePremiumSchool(item.id)
        if (result.status !== 201) {
          this.progress = false;
          this.$root.$emit('notify', {message: `Server returned with status code: ${result.status}`, type: 'error'})
        } else {
          this.$root.$emit('notify', {message: `School ${item.name} deleted.`, type: 'success'})
          this.getDataFromApi()
        }
        this.progress = false
      } catch (e) {
        console.error(e)
        this.$root.$emit('notify', {message: 'Something went wrong'})
      } finally {
        this.progress = false
      }
    },
    openItem(item) {
      if (item) {
        history.pushState(null, "Premium School Editor", `/premium/edit/${item.id}`)
      }

      this.isEditorVisible = true
      setTimeout(() => {
        this.$refs.schoolEditor.load(item)
      }, 50)
    },
    convertDateToString(date) {
      if (date) {
        let timeVal = date
        if (typeof date === 'number') {
          timeVal = parseInt(date) * 1000
        }
        const dateString = new Date(timeVal).toISOString()
        const dateSplit = dateString.split('T')
        return dateSplit.length > 0 ? dateSplit[0] : ''
      } else {
        return ''
      }
    },
    async getDataFromApi() {
      this.loading = true
      const {search, itemsPerPage} = this.options
      try {
        let result = null;
        if (search) {
          if (search !== this.search) {
            this.options.page = 1;
          }
          result = await searchPremiumSchools(search, this.options.page, itemsPerPage)
        } else {
          if (this.search) {
            this.options.page = 1;
          }
          result = await getPremiumSchools(this.options.page, itemsPerPage)
        }
        this.search = search
        this.loading = false
        this.schools = (result || []).map(school => ({
          ...school,
          sub_start_date: this.convertDateToString(school.subscription?.start_date),
          sub_end_date: this.convertDateToString(school.subscription?.end_date),
          status: getMergedSchoolAccountStatus(school.status)
        }))
      } catch (e) {
        console.error(e)
        this.schools = []
      }
      finally {
        this.loading = false
      }
    }
  }

};
</script>

<template>
<v-card>
  <EditorDialog ref="editDialog"></EditorDialog>
  <DeleteDialog ref="deleteDialog" target="School" v-on:delete-dialog-confirmation="deleteCurrentItem($event)"></DeleteDialog>
  <v-card-title>Schools</v-card-title>
	<v-container class="pt-0">
		<v-row>
			<v-col>
				<v-text-field
					v-model="options.search"
					class="mt-0 pt-0"
					append-icon="mdi-magnify"
					label="Search"
					single-line
					clearable
					hide-details
					@click:append="getDataFromApi"
					@keydown.enter="getDataFromApi"
				></v-text-field>
			</v-col>
			<v-col cols="1">
				<v-btn color="primary" @click="gotoSchoolDialog">New...</v-btn>
			</v-col>
		</v-row>
	</v-container>
<v-progress-linear v-if="progress" indeterminate></v-progress-linear>
<v-data-table
    :headers="headers"
    :items="schools"
    :options.sync="options"
    :server-items-length="totalSchools"
    :loading="loading"
    :search="options.search"
    class="elevation-1"
    :footer-props="{'items-per-page-options': [5, 10, 15, 20]}"
    @click:row="openItem"
>
  <template v-slot:item.actions="{ item }">
    <v-icon
        class="mr-2"
        @click.stop.prevent="openItem(item)"
    >
      mdi-pencil
    </v-icon>
    <v-icon color.hover="red"
		@click.stop.prevent="showDeleteDialog(item)"
    >
      mdi-delete
    </v-icon>
  </template>
</v-data-table>
</v-card>
</template>
<script>
import _ from 'lodash'
import EditorDialog from "@/components/school/SchoolEditorDialog";
import DeleteDialog from "@/components/DeleteDialog";
import {EventBus, SCHOOL_CREATED_EVENT, SCHOOL_UPDATED_EVENT} from "@/eventbus";
import {getSchoolTotalCount, getSchools, searchSchools, deleteSchool} from '@/api/api';

export default {

  name: 'SchoolsTable',
  components: {
    EditorDialog, DeleteDialog
  },
  data: () => {
    return {
      progress: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        {text: 'School Name', value: 'name'},
        {text: 'Email Suffix', value: 'email_suffix'},
        {text: 'Country', value: 'country_code'},
        {text: 'City', value: 'city'},
        {text: 'Address', value: 'formatted_address_lines'},
        {text: 'Postal Code', value: 'postal_code'},
        {text: 'Is District', value: 'is_district'},
        {text: 'NCES ID', value: 'nces_id'},
        {text: 'Actions', value: 'actions', sortable: false, width: '100px'},
      ],
      schools: [],
      search: null,
      options: {
        search: ''
      },
      totalSchools: 0,
      loading: false,
    }
  },
  watch: {
    options: {
      handler() {
        this.debouncedGetDataFromApi()
      },
      deep: true,
    }
  },
  async created() {
    this.debouncedGetDataFromApi = _.debounce(function () {
      this.getDataFromApi()
    }, 500, {leading: false})
    await this.getTotalSchoolCount()

    EventBus.$on(SCHOOL_CREATED_EVENT, async () => {
      await this.getTotalSchoolCount()
      await this.getDataFromApi()
    })

    EventBus.$on(SCHOOL_UPDATED_EVENT, (item) => {
      const school = _.find(this.schools, {id: item.id})
      if (school) {
        _.assign(school, item);
      }
    })
  },
  methods: {
    showDeleteDialog(item) {
      this.$refs.deleteDialog.show(item.name, item);
    },
    async getTotalSchoolCount() {
      this.totalSchools = await getSchoolTotalCount()
    },
    async deleteCurrentItem(item) {
      this.progress = true
      try {
        const result = await deleteSchool(item.id)
        if (result.status !== 201) {
          this.progress = false;
          this.$root.$emit('notify', {message: `Server returned with status code: ${result.status}`, type: 'error'})
        } else {
          this.$root.$emit('notify', {message: `School ${item.name} deleted.`, type: 'success'})
          this.getDataFromApi()
        }
        this.progress = false
      } catch (e) {
        console.error(e)
        this.$root.$emit('notify', {message: 'Something went wrong', type: 'error'})
      } finally {
        this.progress = false
      }
    },
    openItem(item) {
      this.$refs.editDialog.show(item)
    },
    async getDataFromApi() {
      this.loading = true
      const {search, itemsPerPage} = this.options
      try {
        let result = null;
        const pageSize = itemsPerPage === -1 ? this.totalSchools : itemsPerPage
        if (search) {
          if (search !== this.search) {
            this.options.page = 1;
          }
          result = await searchSchools(search, this.options.page, pageSize)
        } else {
          if (this.search) {
            this.options.page = 1;
          }
          result = await getSchools(this.options.page, pageSize)
        }
        this.search = search
        this.loading = false
        this.schools = result || []
      } catch (e) {
        console.error(e)
      }
      finally {
        this.loading = false
      }
    },
		gotoSchoolDialog() {
      this.$refs.editDialog.show({ active: true })
		}
  }

};
</script>

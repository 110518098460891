<template>
	<v-card>
		<EditorDialog ref="editDialog"></EditorDialog>
		<DeleteDialog ref="deleteDialog" target="Custom School" v-on:delete-dialog-confirmation="deleteCurrentItem($event)"></DeleteDialog>
		<v-card-title>Custom Schools</v-card-title>
		<v-container class="pt-0">
			<v-row>
				<v-col cols="6">
					<v-text-field
						v-model="options.search"
						class="mt-0 pt-0"
						append-icon="mdi-magnify"
						label="Search"
						single-line
						clearable
						hide-details
						@click:append="getDataFromApi"
						@keydown.enter="getDataFromApi"
					></v-text-field>
				</v-col>
			</v-row>
		</v-container>
		<v-progress-linear v-if="progress" indeterminate></v-progress-linear>
		<v-data-table
				:headers="headers"
				:items="schools"
				:options.sync="options"
				:server-items-length="totalSchools"
				:loading="loading"
				:search="options.search"
				class="elevation-1"
				@click:row="openItem"
		>
      <template v-slot:item.countryCode="{ item }">
        {{ getCountryName(item) }}
      </template>
			<template v-slot:item.actions="{ item }">
				<v-icon
						class="mr-2"
						@click.stop.prevent="openItem(item)"
				>
					mdi-pencil
				</v-icon>
				<v-icon color.hover="red"
				@click.stop.prevent="showDeleteDialog(item)"
				>
					mdi-delete
				</v-icon>
			</template>
		</v-data-table>
	</v-card>
</template>
<script>
import _ from 'lodash'
import EditorDialog from "@/components/school/SchoolEditorDialog";
import DeleteDialog from "@/components/DeleteDialog";
import {EventBus, CUSTOM_SCHOOL_CONVERTED_EVENT} from "@/eventbus";
import {getCustomSchoolTotalCount, getCustomSchools, searchCustomSchools, deleteCustomSchool} from '@/api/api';
import { COUNTRY_DATA } from '@/constants';

export default {

  name: 'CustomSchoolsTable',
  components: {
    EditorDialog, DeleteDialog
  },
  data: () => {
    return {
      progress: false,
      dialog: false,
      headers: [
        {text: 'School Name', value: 'name'},
        {text: 'Country', value: 'countryCode'},
        {text: 'Students', value: 'studentsCount'},
        {text: 'Teacher Name', value: 'teacher.name'},
        {text: 'Teacher Email', value: 'teacher.email'},
        {text: 'Address', value: 'address'},
				{text: 'Actions', value: 'actions', sortable: false, width: '100px'},
      ],
      schools: [],
      search: null,
      options: {
        search: ''
      },
      totalSchools: 0,
      loading: false
    }
  },
  watch: {
    options: {
      handler() {
        this.debouncedGetDataFromApi()
      },
      deep: true,
    }
  },
  async created() {
    this.debouncedGetDataFromApi = _.debounce(function () {
      this.getDataFromApi()
    }, 500, {leading: false})
    await this.getTotalSchoolCount()

    EventBus.$on(CUSTOM_SCHOOL_CONVERTED_EVENT, () => {
      this.debouncedGetDataFromApi()
    })
  },
  methods: {
    showDeleteDialog(item) {
      this.$refs.deleteDialog.show(item.name, item);
    },
    async getTotalSchoolCount() {
      this.totalSchools = await getCustomSchoolTotalCount()
    },
    async deleteCurrentItem(item) {
      this.progress = true
      try {
        const result = await deleteCustomSchool(item.id)
        if (result.status !== 201) {
          this.progress = false;
          this.$root.$emit('notify', {message: `Server returned with status code: ${result.status}`, type: 'error'})
        } else {
          this.$root.$emit('notify', {message: `School ${item.name} deleted.`, type: 'success'})
          this.getDataFromApi()
        }
        this.progress = false
      } catch (e) {
        console.error(e)
        this.$root.$emit('notify', {message: 'Something went wrong'})
      } finally {
        this.progress = false
      }
    },
    getCountryName(item) {
      return _.find(COUNTRY_DATA, (country) => country.alpha2 === item.countryCode)?.name
    },
    openItem(item) {
      this.$refs.editDialog.show(
        {
          name: item.name,
          formatted_address_lines: _.filter([item.address, item.address2], (o) => !!o).join(";"),
          administrative_area: item.state,
          country_code: _.find(COUNTRY_DATA, (country) => country.alpha2 === item.countryCode)?.alpha3,
          postal_code: item.zipCode,
          city: item.city,
          phone_number: item.phoneNumber,
          students_count: item.studentsCount,
          active: true,
          custom_school_id: item.id,
        },
        item.address,
      );
    },
    async getDataFromApi() {
      this.loading = true
      const {search, itemsPerPage} = this.options
      try {
        let result = null;
        if (search) {
          if (search !== this.search) {
            this.options.page = 1;
          }
          result = (
            await searchCustomSchools(search, this.options.page, itemsPerPage)
          ).sort((a, b) => a.name.localeCompare(b.name));
        } else {
          if (this.search) {
            this.options.page = 1;
          }
          result = (
            await getCustomSchools(this.options.page, itemsPerPage)
          ).sort((a, b) => a.name.localeCompare(b.name));
        }
        this.search = search
        this.loading = false
        this.schools = result || []
      } catch (e) {
        console.error(e)
      }
      finally {
        this.loading = false
      }
    }
  }

};
</script>

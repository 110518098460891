<template>
  <div>
    <CustomSchoolsTable/>
  </div>
</template>

<script>
import CustomSchoolsTable from "@/components/school/CustomSchoolTable";
export default {
  name: 'CustomSchools',
  components: {CustomSchoolsTable}
}
</script>
import { render, staticRenderFns } from "./PremiumSchoolEditor.vue?vue&type=template&id=f26c0ae4&scoped=true&"
import script from "./PremiumSchoolEditor.vue?vue&type=script&lang=js&"
export * from "./PremiumSchoolEditor.vue?vue&type=script&lang=js&"
import style0 from "./PremiumSchoolEditor.vue?vue&type=style&index=0&id=f26c0ae4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f26c0ae4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardText,VChip,VCombobox,VContainer,VDataTable,VDivider,VFileInput,VIcon,VProgressLinear,VSelect,VStepper,VStepperContent,VStepperStep,VTextField})

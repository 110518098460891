<template>
  <div>
    <PremiumSchoolsTable/>
  </div>
</template>
<script>
import PremiumSchoolsTable from "@/components/school/PremiumSchoolTable";
export default {
  name: 'PremiumSchools',
  components: {PremiumSchoolsTable}
}
</script>

<template>

  <div>
      <v-stepper v-model="stepper">
          <v-btn
              class="float-right mt-2 mr-4"
              icon
              dark
              @click="closeEditor"
            >
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
          <v-stepper-step
              editable
              step="1"
              :complete="stepper > 1"
          >
            Locate or input subscription info
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-card  :disabled="inProgress" class="pr-6">
              <SearchAutoComplete
                label="School"
                placeholder="Search for a school info..."
                :isMultiple="false"
                :searchList="getSchoolDataFromApi"
                :selectedItem="selectedSchool"
                :error="schoolErrors.length > 0"
                :errorMessages="schoolErrors"
                :onSelected="selectSchool"
              />
              <div class="px-4">
                <v-select
                  :items="statuses"
                  label="Status"
                  v-model="editItem.status"
                ></v-select>
              </div>
              <subscription-editor :disabled="inProgress"></subscription-editor>
              <v-btn :disabled="inProgress" class="mr-2" color="primary" @click="saveSchoolAccount" v-if="editItem.id">Save</v-btn>
              <v-btn :disabled="inProgress" color="primary" @click="gotoSelectUsers()">Continue</v-btn>
            </v-card>
          </v-stepper-content>

          <v-stepper-step
              editable
              step="2"
              :complete="stepper > 2"
          >
            Select Users
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-card  :disabled="inProgress">
              <v-card-text>
                <v-text-field
                  v-model="search"
                  placeholder="Search (e.g. @readtheory.org for all users with emails and usernames with @readtheory.org in them)"
                >
                </v-text-field>
                <v-btn
                    color="primary"
                    class="ma-0"
                    @click="getDataFromApi()"
                    :disabled="inProgress || search===''">

                  Search
                </v-btn>
                <div class="mt-3">Or exact results from file or input (e.g. stacy@readtheory.org)</div>
                <v-file-input
                    v-model="files"
                    truncate-length="15"
                    placeholder="Upload your CSV"
                    @change="loadCSV"

                ></v-file-input>
                <v-combobox
                    v-model="usernames"
                    :items="usernames"
                    label="Users"
                    multiple
                    chips
                    :disabled="inProgress"
                ></v-combobox>
              </v-card-text>
              <v-btn class="mr-2" :disabled="inProgress" color="secondary" @click="reset(2)">Clear List</v-btn>
              <v-btn :disabled="inProgress" color="primary" @click="preview">Continue</v-btn>
            </v-card>
          </v-stepper-content>


          <v-divider></v-divider>

          <v-stepper-step
              step="3"
              :editable="stepper > 2"
              :complete="stepper > 3 && this.table.items.length > 0"
          >
            Preview matches from database
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-card :disabled="inProgress">
              <v-card-text class="warning" v-if="not_found_usernames.length > 0"><b>Did not find {{not_found_usernames.length}} of the users/emails provided: {{not_found_usernames.join(',')}}</b></v-card-text>
              <v-data-table :disabled="inProgress"
                              :headers="table.headers"
                              :items="table.items"
                              :loading="table.loading"
                              :options="table.options"
                              item-key="id"
                              class="elevation-1"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-btn :disabled="inProgress" @click="removeItem(item)"
                           x-small
                           color="secondary"
                           dark
                    >
                      remove from list
                      <v-icon
                          small

                      >
                        mdi-close-circle
                      </v-icon>

                    </v-btn>


                  </template>
                  <template v-slot:item.roles="{ item }">
                    <v-chip v-for="role in item.roles" :key="role"
                            close-icon="mdi-close-outline"
                            :color="getRoleColor(role)"
                            label
                            x-small
                    >{{getRoleName(role)}}</v-chip>
                  </template>
                </v-data-table>
            </v-card>
            <v-btn class="mt-4 mr-4" color="secondary" :disabled="inProgress || table.items.length < 1" @click="removeMatches">
              Remove all from list
            </v-btn>
            <v-btn class="mt-4" color="primary" :disabled="inProgress || table.items.length < 1" @click="confirmCreate">
              {{ submitText }}
            </v-btn>
          </v-stepper-content>

          <v-divider></v-divider>

          <v-progress-linear v-if="!finished && inProgress" indeterminate color="primary"/>
          <v-container v-show="finished">
            <div>{{finishedText}}</div>
            <v-btn @click="reset">Edit Again</v-btn>
          </v-container>

      </v-stepper>
      <div class="mt-6">
        <UserTree v-if="!isLoading" :root-object="editItem"></UserTree>
      </div>
 </div>
</template>
<script>

import {
  CMS_API_PREFIX,
  ROLE_ADMIN,
  ROLE_STUDENT,
  ROLE_TEACHER,
  ROLES,
  SUB_SERVICE_API,
  SUB_SERVICE_SECRET
} from "@/constants";
import {mapMutations, mapState} from "vuex";
import SubscriptionEditor from "@/components/SubscriptionEditor";
import SearchAutoComplete from "./SearchAutoComplete";
import UserTree from "@/components/tree/UserTree"
import {SCHOOL_ACCOUNT_NODE_TYPE} from "@/components/tree/Nodes/user_tree_node_types"
import { SCHOOL_ACCOUNT_STATUS_LIST } from '@/constants'
import {EventBus, NOTIFY_EVENT, PREMIUM_SCHOOL_CREATED_EVENT, PREMIUM_SCHOOL_UPDATED_EVENT} from "@/eventbus";
import { getSchools, searchSchools, getPremiumSchoolAccount, createPremiumSchool, updatePremiumSchool } from '@/api/api'
import _ from "lodash";
import {getConnectedTeachersForAccount} from '@/api/api';
import { getMergedSchoolAccountStatus } from '@/utils'

export default {
  name: 'BulkDelete',
  components: {
    SubscriptionEditor, SearchAutoComplete, UserTree
  },
  data() {
    return {
      isLoading: false,
      editItem: {},
      error: null,
      search: '',
      menu: false,
      files: [],
      stepper: 1,
      countdownTimeout: null,
      countdown: 15,
      inProgress: false,
      finished: false,
      finishedText: '',
      schoolErrors: [],
      statuses: SCHOOL_ACCOUNT_STATUS_LIST,
      schoolList: [],
      selectedSchool: null,
      teachers: [],
      table: {
        items: [],
        options: {
          itemsPerPage: -1,
        },
        headers: [
          {text: 'Actions', value: 'actions', sortable: false },
          {text: 'Role', value: 'roles', sortable :false},
          {text: 'id', value: 'id'},
          {text: 'username', value: 'username'},
          {text: 'E-Mail', value: 'email'},
          {text: 'First Name', value: 'first_name'},
          {text: 'Last Name', value: 'last_name'},
          {text: 'Last seen at', value: 'last_login_date'},
        ],
        loading: false
      }
    }
  },
  computed: {
    ...mapState(['bulk_subscriptions']),
    submitText() {
      return `${ !this.editItem.id ? 'Create' : 'Update' } premium schools for all ${this.table.items.length} users above`
    },
    subscriptionObj: {
      get() {
        return this.bulk_subscriptions.subscriptionObj
      },
      set(value) {
        this.SET_SUB_OBJ(value)
      }
    },
    usernames: {
      get() {
        return this.bulk_subscriptions.usernames;
      },
      set(value) {
        this.SET_USERNAMES_FOR_BULK_SUB(value);
      }
    },
    not_found_usernames() {
      if (this.table.loading) return []
      let not_found = _.clone(this.usernames)
      const usernames_found = this.table.items.map(item => [item.username, item.email]).flatMap(x => x)
      not_found = _.difference(not_found, usernames_found)
      return not_found
    },
    querySeachParams() {
      let url = ''
      if (!_.isEmpty(this.search) || (this.search && this.search.length > 2)) {
        url = 'query=' + encodeURIComponent(this.search);
        const search_fields = ['username','email']
        if (!_.isEmpty(search_fields)) url += '&query_fields=' + _.join(search_fields, ',')
      }
      return url;
    },
  },
  async created() {
    if (this.$route.params.id) {
      const premiumSchool = await getPremiumSchoolAccount(this.$route.params.id)
      this.load(premiumSchool)
    }
  },
  methods: {
    ...mapMutations(['SET_USERNAMES_FOR_BULK_SUB','SET_SCHOOL_NAME', 'SET_SUB_OBJ']),
    load(item) {
      this.editItem = item || {}
      this.editItem.type = SCHOOL_ACCOUNT_NODE_TYPE
      this.editItem.status = getMergedSchoolAccountStatus(this.editItem.status)

      if (item) {
        this.selectedSchool = {
          id: this.editItem.school_info_id,
          name: this.editItem.name,
          formatted_address_lines: 'Unknown'
        }
        this.subscriptionObj = item.subscription

        if (item.id) {
          this.loadConnectedTeachers();
        }
      }
    },
    async loadConnectedTeachers() {
      const {data} = await getConnectedTeachersForAccount(this.editItem.id)
      this.teachers = data?.items || []
    },
    reset(step = 1) {
      this.files = [];
      this.usernames = [];
      this.stepper = step;
      if (this.countdownTimeout) clearInterval(this.countdownTimeout);
      this.countdownTimeout = null;
      this.countdown = 15;
      this.inProgress = false;
      this.finished = false;
      this.table.items = [];
      this.finishedText = '';
    },
    async loadCSV(file) {
      // eslint-disable-next-line no-undef
      if (file) {
        this.$papa.parse(file, {
          complete: (res) => {
            this.usernames = res.data.flatMap(line => line).map(username => username.trim());
          }
        })
      }
    },
    getRoleColor(roleId) {
      if (roleId === ROLE_TEACHER) return 'orange'
      if (roleId === ROLE_STUDENT) return 'yellow'
      if (roleId === ROLE_ADMIN) return 'red'
      return 'blue'
    },
    getRoleName(roleId) {
      return ROLES[roleId];
    },
    removeItem(item) {
      this.table.items = this.table.items.filter(it => it.id !== item.id)
    },
    removeMatches() {
      this.table.items = []
      this.stepper = 2
    },
    async preview() {
      if (!this.usernames || this.usernames.length === 0) {
        this.$root.$emit('notify', {message: 'Please select users', type: 'warning'})
        return
      }

      this.stepper = 3
      let url = `${CMS_API_PREFIX}/user/by_usernames?usernames=${encodeURIComponent(this.usernames.join(','))}`;
      this.table.loading = true
      try {
        const {data, status} = await this.axios.get(url);
        if (status !== 200) {
          this.$root.$emit('notify', {message: `Server returned with status code: ${status}`, type: 'error'})
          this.table.loading = false;
          return;
        }
        const newItems = data.items.map(item => ({...item, roles: JSON.parse(item.roles)}));
        this.table.items = _.unionBy(this.table.items, newItems, 'id')
      }
      catch (e) {
        console.error(e);
      }
      finally {
        this.table.loading = false
      }
    },
    getSubscriptionDate(dateValue, isStartDate = true) {
      if (!dateValue) {
        if (isStartDate) {
          return (new Date().getTime() / 1000)
        } else {
          return null
        }
      } else {
        if (typeof dateValue === 'string') {
          return dateValue;
        } else {
          return parseInt(dateValue)
        }
      }
    },
    getSelectedUserIds() {
      let userIds = this.table.items ? this.table.items.map(item => item.id) : [];
      const connectedUserIds = this.teachers ? this.teachers.map(item => item.user_id) : [];
      userIds = userIds.concat(connectedUserIds);
      return userIds.filter((user_id, idx) => userIds.indexOf(user_id) === idx);
    },
    getPremiumSchoolData() {
      return {
        id: this.editItem.id,
        school_info_id: this.selectedSchool?.id,
        status: this.editItem.status,
        subscription: {
          ...this.subscriptionObj,
          start_date: this.getSubscriptionDate(this.subscriptionObj.start_date),
          end_date: this.getSubscriptionDate(this.subscriptionObj.end_date, false)
        },
        user_ids: this.getSelectedUserIds()
      }
    },
    notify(message, type) {
      EventBus.$emit(NOTIFY_EVENT, {message, type})
    },
    async createPremiumSchoolAccount() {
      try {
        const result = await createPremiumSchool(this.getPremiumSchoolData())
        if (result && result.id) {
          this.notify(`Premium school ${this.selectedSchool?.name} was created`, 'success')
          EventBus.$emit(PREMIUM_SCHOOL_CREATED_EVENT)
        } else {
          return false
        }
      } catch {
        return false
      }
      return true
    },
    async updatePremiumSchoolAccount() {
      try {
        const result = await updatePremiumSchool(this.getPremiumSchoolData())
         if (result && result.id) {
          this.notify(`Premium school ${this.selectedSchool?.name} was updated`, 'success')
          EventBus.$emit(PREMIUM_SCHOOL_UPDATED_EVENT, result)
        } else {
          return false
        }
      } catch {
        return false
      }
      return true
    },
    async submitSchoolAccount() {
      this.inProgress = true;
      let success = await this.bulk_update_user_fields();
      success = success && await this.bulk_update_subs();
      if (!this.editItem.id) {
        success = success && await this.createPremiumSchoolAccount();
      } else {
        success = success && await this.updatePremiumSchoolAccount();
      }
      this.inProgress = false;
      return success
    },
    async saveSchoolAccount() {
      const success = await this.submitSchoolAccount();
      if (!success) {
        this.finishedText = 'There were some issues';
      }
    },
    async confirmCreate() {
      const success = await this.submitSchoolAccount();

      this.finished = true;
      if (success) {
        this.closeEditor()
      } else {
        this.finishedText = 'There were some issues'
      }
    },
    gotoSelectUsers() {
      if (!this.selectedSchool) {
        this.schoolErrors = ['School is required']
      } else {
        this.stepper = 2;
      }     
    },
    async getDataFromApi() {
      this.stepper=3
      this.table.loading = true
      let url = `/user/list?`
      url += `limit=100&offset=0&sort=username&desc=false&query_search_inline=true&${this.querySeachParams}`;
      if (url !== this.lastFetchQuery) {
        this.isLoading = true
        try {
          if (this.cancelToken) {
            this.cancelToken.cancel("Operation canceled due to new request.")
          }
          this.cancelToken = this.axios.CancelToken
          const {data, status} = await this.axios(url, {cancelToken: this.cancelToken});
          if (status !== 200) {
            this.isLoading = false;
            this.$root.$emit('notify', {message: `Server returned with status code: ${status}`, type: 'error'})
          }
          const newItems = data.items.map(item => ({...item, roles: JSON.parse(item.roles), user_settings: JSON.parse(item.user_settings)}));
          this.table.items = _.unionBy(this.table.items, newItems, 'id')
        }
        catch (e) {
          console.error(e);
        }
        finally {
          this.isLoading = false
          this.table.loading = false
        }
      }
    },
    async getSchoolDataFromApi(search) {
      let schoolData = []
      if (!search) {
        schoolData = await getSchools(1, 10)
      } else {
        schoolData = await searchSchools(search, 1, 10)
      }
      this.schoolList = schoolData || []
      return this.schoolList
    },
    selectSchool(schoolId) {
      if (schoolId) {
        this.selectedSchool = this.schoolList.find(schoolItem => schoolItem.id === schoolId)
        this.schoolErrors = []
      } else {
        this.selectedSchool = null
      }
    },
    closeEditor() {
      if (this.$route.params.id) {
        this.$router.go(-1)
      } else {
        history.go(-1)
        this.$emit('onClose')
      }
    },
    getSelectedUsers() {
      let selectedUsers = this.table.items || [];
      if (this.teachers) {
        const connectedUsers = this.teachers.map(teacher => ({
          ...teacher,
          id: teacher.user_id
        }));
        selectedUsers = selectedUsers.concat(connectedUsers);
      }
      const userIds = selectedUsers.map(user => user.id);
      return selectedUsers.filter((user, idx) => userIds.indexOf(user.id) === idx);
    },
    async bulk_update_user_fields() {
      const tableItems = this.getSelectedUsers(); 
      if (_.isEmpty(this.selectedSchool?.name) || tableItems.length < 1) return true;
      const payload = {
        user_ids: tableItems.map(item => item.id),
        fields: {
          school_name: this.selectedSchool?.name,
          subscription: this.subscriptionObj
        }
      }
      let url = `${CMS_API_PREFIX}/user/update_user_settings`;
      try {
        const {data, status} = await this.axios.post(url, payload);
        if (status !== 200) {
          this.$root.$emit('notify', {message: `Server returned with status code: ${status}`, type: 'error'})
          return;
        }
        return true
      }
      catch (e) {
        console.error(e);
        this.$root.$emit('notify', {message: e.message, type: 'error'})
      }
    },
    async bulk_update_subs() {
      const issues = [];
      const tableItems = this.getSelectedUsers(); 

      if (tableItems.length < 1) return;
      const url =  `${SUB_SERVICE_API}/subs/${this.subscriptionObj.active ? 'activate' : 'disable'}`
      for (const item of tableItems) {
        try {
          const payload = {
            user_id: item.id,
            secret_key: SUB_SERVICE_SECRET,
            skip_stripe: true,
            customer_id: this.subscriptionObj.customer_id,
            opt_sub_obj: this.subscriptionObj
          }
          const {data, status} = await this.axios.post(url, payload);
          if (status !== 200) {
            issues.push(item.username)
          }
        } catch (e) {
          console.error(e);
          issues.push(item.username)
        }
      }
      if (issues.length > 0) {
        this.$root.$emit('notify', {message: `could not update users: ${issues.join(',')}`, type: 'error'})
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-form {
  padding: 0 !important;
}
</style>
<template>
  <v-treeview
      :active.sync="active"
      :items="items"
      :load-children="fetchItems"
      :open.sync="open"
      activatable
      transition
  >
    <template v-slot:label="{item, active, open}">
            <span v-if="active && !item.type.endsWith('list')">
              <v-btn color="blue" text @click.prevent="edit(item)">
                              <v-icon class="mr-2" color="blue">
              {{ item.icon }}
              </v-icon>
                {{ item.name }}
                <v-icon small class="ml-2">mdi-pencil</v-icon>
                EDIT
              </v-btn>
              <v-btn v-if="item.type==='teacher' || item.type==='student'" color="blue" text
                     @click.prevent="">
                <v-icon small class="mr-2" color="blue">
                    mdi-account-remove
                </v-icon>
                Stop Tracking
              </v-btn>
              <v-btn v-if="item.type==='teacher' || item.type==='student'" color="red" text
                     @click.prevent="">
                <v-icon small class="mr-2" color="red">
                    mdi-account-remove
                </v-icon>
                Add To Bulk Delete
              </v-btn>
              <!--              <v-btn v-if="item.type='class'" color="blue" text @click.prevent="copyToTeacher(item)">-->
              <!--              <v-icon small class="mr-2" color="blue">-->
              <!--                mdi-content-copy-->
              <!--              </v-icon>-->
              <!--                Copy To Another Teacher-->
              <!--              </v-btn>-->

            </span>
      <span v-else-if="open && item">
              <v-icon class="mr-2">
              {{ item.icon }}
              </v-icon>{{ item.name }}
              <v-btn color="red" text @click.prevent="">
                <v-icon small class="mr-2" color="red">
                    mdi-account-remove
                </v-icon>
                Add To Bulk Delete Screen
              </v-btn>
            </span>
      <span v-else>
              <v-icon class="mr-2">
              {{ item.icon }}
              </v-icon>{{ item.name }}</span>
    </template>
  </v-treeview>
</template>
<script>
import _ from "lodash";
import {boAxios} from "@/network";

export default {
  name: 'SubPlansTreeView',
  components: {},
  props: ['rootObject'],
  data: () => ({
    show: true,
    active: [],
    avatar: null,
    open: [],
    products: [],
    prices: [],
    root: null,
    items: []
  }),
  mounted() {
    this.fetchItems();
  },
  methods: {
    async fetchItems() {
      const {data} = await fetch('https://subscriptions-service.readtheory.org/products/list').then(res=>res.json())
      this.items = data
    },
  }
}

</script>
